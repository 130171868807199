(function(){
    let scCounter = 0
    let scOpen;

    let slideUpHandler = (moevent) => {
        // Bubbling effect! Go to top, then to the desired element
        let elem = Array.from(moevent.target.closest('.slidecard').children).filter(
            a => a.classList.contains('slidecard__textbox')
        )[0]

        if ( ! elem.dataset.suid) {
            elem.dataset.suid = ++scCounter
        }

        if (scOpen === elem.dataset.suid) return

        if (elem.classList.contains('slidecard__textbox--closed')){
            elem.classList.remove('slidecard__textbox--closed')
            elem.classList.add('slidecard__textbox--open')

            if (scOpen){
                let curOpen = document.querySelector(`[data-suid="${scOpen}"]`)
                curOpen.classList.remove('slidecard__textbox--open')
                curOpen.classList.add('slidecard__textbox--closed')
            }

            scOpen = elem.dataset.suid
        }
        setTimeout(() => resizeHandlers(), 1000)
    }

    const bindHandlers = () => {
        Array
            .from(document.getElementsByClassName('slidecard'))
            .forEach(e => {
                e.addEventListener('mouseover', slideUpHandler)
                e.addEventListener('touchstart', slideUpHandler)
                e.addEventListener('clusterClickEvent', slideUpHandler)
            })
    }

        // not proud for this one, but didn't found a proper css solution in the hurry
    const resizeHandlers = () => {
        if ( document.querySelector('.slidecard__textbox--open .slidecard__text') ) {
            const fullH = document.querySelector('.slidecard__textbox--open')?.offsetHeight
            const titleH = document.querySelector('.slidecard__textbox--open .slidecard__iconbox')?.offsetHeight
            const btnH = document.querySelector('.slidecard__textbox--open .slidecard__button a')?.offsetHeight
            const offsetH = 15
            const txtSize = fullH - ( titleH ?? 0 ) - ( btnH ?? 0 ) - ( offsetH ?? 0 )
            document.querySelector('.slidecard__textbox--open .slidecard__text').style.maxHeight = txtSize + 'px'
        }
    }

    const oldOnloads = window.onload
    window.onload = () => {
        if ( oldOnloads ) oldOnloads()
        bindHandlers()
    }

    const oldResizes = window.onresize
    window.onresize = () => {
        if (oldResizes) oldResizes()
        resizeHandlers()
    }
})()