<template>
  <div>
    <!-- FILTER -->
    <div class="mt-4 mt-md-8">
      <ul class="filters">
        <li
            v-for="category in activeCategories" @click.prevent="switchCategory(category)"
            style="cursor: pointer"
            v-bind:class="{'active-filter' : activeFilter.id == category.id}"
        >{{ category.name }}
        </li>
      </ul>
    </div>
    <!-- FILTER END -->

    <!-- PARTNERS -->
    <div class="row mt-4 mt-md-6">
      <div v-if="loading" class="col-12">
        <div class="py-5 mb-10  d-flex justify-content-center align-items-center">
          <div class="dots-6"></div>
        </div>
      </div>
      <div class="col-md-4 mb-5"
           v-for="(partner, index) in filteredPartners"
           v-match-heights="{ el : ['.news-card']}"
           v-if="! loading && index < showItems"
      >
        <div class="news-card card-shadow">
          <div class="news-card__header p-2">
            <img :src="partner.logo" :alt="partner.name"/>
          </div>
          <div class="news-card__footer">
            <a :href="partner.link_href" target="_blank"
               class="button button--reverse"> {{ t('app.teaser.partner.go_to_website') }} </a>
          </div>
        </div>
      </div>
    </div>
    <!-- PARTNERS END -->
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: "NetworkPartner",
  props: {
    locale: {
      type: String,
      default: 'de'
    },
    translations: {
      type: Object
    },
    categories: {
      type: Array,
      default: []
    }
  },
  data() {
    return {
      activeCategories: [],
      partners: [],
      defaults: {
        'de': 'Alle',
        'en': 'All'
      },
      activeFilter: {},
      loading: true,
      showItems: 12,
      baseShowItems: 12,
      showIncrement: 3,
      scrollThreshold: 100
    }
  },
  computed: {
    filteredPartners() {
      if (this.activeFilter.id === -1) return this.partners
      return this.partners
          .filter((e) => e.categoryId === this.activeFilter.id)
    }
  },
  created() {
    window.addEventListener('scroll', this.scrollHandler)
    this.activeFilter = {id: -1, name: this.defaults[this.locale]}
    this.activeCategories = [this.activeFilter, ...this.categories
        .map(c => {
          return {
            'id': c[0],
            'name': c[1]
          }
        })
    ]
    this.loadData()
  },
  methods: {
    t(key) {
      if(key in this.translations) {
        return this.translations[key]
      }
      return key
    },
    switchCategory(newActive) {
      this.activeFilter = newActive;
      this.showItems = this.baseShowItems
    },
    scrollHandler(event) {
      if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight - this.scrollThreshold) {
        this.showItems += this.showIncrement
      }
    },
    loadData() {
      axios
          .get(`/api/rest/${this.locale}/get-networkpartner`, {
            params: {
              category_ids: this.categories.map(c => c[0])
            }
          })
          .then((e) => {
            this.partners = e.data.partners //.sort((p1, p2) => p1.name > p2.name ? -1 : 1)
            this.loading = false
            // this.categories = [this.activeFilter, ...e.data.categories]
          })
    }
  }
}
</script>


<style scoped lang="scss">

.loader-div {
  min-height: 100px;
}

.dots-6 {
  color: #af0735;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  box-shadow: 19px -19px 0 0px, 38px -19px 0 0px, 57px -19px 0 0px,
  19px 0 0 5px, 38px 0 0 5px, 57px 0 0 5px,
  19px 19px 0 0px, 38px 19px 0 0px, 57px 19px 0 0px;
  transform: translateX(-38px);
  animation: d6 2s infinite linear;
}

@keyframes d6 {
  12.5% {
    box-shadow: 19px -19px 0 0px, 38px -19px 0 0px, 57px -19px 0 5px,
    19px 0 0 5px, 38px 0 0 0px, 57px 0 0 5px,
    19px 19px 0 0px, 38px 19px 0 0px, 57px 19px 0 0px
  }
  25% {
    box-shadow: 19px -19px 0 5px, 38px -19px 0 0px, 57px -19px 0 5px,
    19px 0 0 0px, 38px 0 0 0px, 57px 0 0 0px,
    19px 19px 0 0px, 38px 19px 0 5px, 57px 19px 0 0px
  }
  50% {
    box-shadow: 19px -19px 0 5px, 38px -19px 0 5px, 57px -19px 0 0px,
    19px 0 0 0px, 38px 0 0 0px, 57px 0 0 0px,
    19px 19px 0 0px, 38px 19px 0 0px, 57px 19px 0 5px
  }
  62.5% {
    box-shadow: 19px -19px 0 0px, 38px -19px 0 0px, 57px -19px 0 0px,
    19px 0 0 5px, 38px 0 0 0px, 57px 0 0 0px,
    19px 19px 0 0px, 38px 19px 0 5px, 57px 19px 0 5px
  }
  75% {
    box-shadow: 19px -19px 0 0px, 38px -19px 0 5px, 57px -19px 0 0px,
    19px 0 0 0px, 38px 0 0 0px, 57px 0 0 5px,
    19px 19px 0 0px, 38px 19px 0 0px, 57px 19px 0 5px
  }
  87.5% {
    box-shadow: 19px -19px 0 0px, 38px -19px 0 5px, 57px -19px 0 0px,
    19px 0 0 0px, 38px 0 0 5px, 57px 0 0 0px,
    19px 19px 0 5px, 38px 19px 0 0px, 57px 19px 0 0px
  }
}
</style>
