const ts_matchHeight = function ($key){
    let mhs = Array.from(document.getElementsByClassName($key))
    if(mhs){
    let mhContainer = [];
    mhs.forEach(elem => mhContainer.push(elem.closest('.row')))
    mhContainer
        .filter((elem, key) => mhContainer.indexOf(elem) === key)
        .forEach(wrap => {
            let inContainer = Array.from(wrap.getElementsByClassName($key))
            let maxSize = inContainer.sort((a, b) =>
                a.offsetHeight > b.offsetHeight ? -1 : 1
            )[0].offsetHeight
            inContainer.forEach(e => e.style.height = maxSize + 'px')
        })
    }
}

ts_matchHeight('match-height');

window.addEventListener('resize', () => {
    setTimeout(() => {
        ts_matchHeight('match-height');
    }, 200)
});