<template>
    <section class="section-event text-center">
        <!-- FILTER -->
        <div class="mt-4 mt-md-8" v-if="withFilters && activeCategories.length > 1">
            <ul class="filters">
                <li v-for="category in activeCategories"
                    @click.prevent="activeFilter = category"
                    v-bind:class="{ 'active-filter' : activeFilter.id == category.id }"
                >{{ category.name }} </li>
            </ul>
        </div>
        <!-- FILTER END -->
        <div class="container">
            <div class="section-event__cards">
                <a :href="event.link" class="event-card" v-for="event in filteredEvents">
                    <figure class="event-card__img">
                        <img :src="event.thumbnail" alt="placeholder"/>
                    </figure>

                    <div class="event-card__body">
                        <div class="event-card__text-block">
                            <div class="event-card__date">{{ event.date }}</div>
                            <h4 class="event-card__heading">{{ event.title }}</h4>
                            <div v-html="event.short"></div>
                        </div>
                        <div class="d-flex flex-column ml-lg-6 justify-content-between">
                            <div class="d-flex justify-content-end event-card__icons">
                                <img v-for="img in getActiveIcons(event)"  :src="img.src" >
                            </div>
                            <div class="button button--primary button--sm text-center">
                                {{ t('event.teaser.link') }}
                                <i v-if="! event.public" class="fas" v-bind:class="{ 'fa-lock-open' : approvedUser, 'fa-lock' : !approvedUser}"></i>
                            </div>

                        </div>
                    </div>
                </a>
            </div>
        </div>
    </section>
</template>

<script>
import axios from 'axios'

export default {
    name: "EventOverview",
    props: {
        withFilters : {
            type: Boolean,
            default: true
        },
        locale: {
            type: String,
            default: 'de'
        },
        translations: {
            type: Object
        },
        categories : {
            type: Array,
            default: []
        }
    },
    data() {
        return {
            activeCategories: [],
            defaults: {
                'de': 'Alle',
                'en': 'All'
            },
            approvedUser: false,
            icons : [
                { name : 'ict', src: '/images/icons/png/ict.png'},
                { name : 'medical', src: '/images/icons/png/medical.png'},
                { name : 'pharma', src: '/images/icons/png/pharma.png'},
                { name : 'products', src: '/images/icons/png/products.png'},
                { name : 'technologies', src: '/images/icons/png/technologies.png'},
                { name : 'innovation', src: '/images/icons/png/innovation.png'},
                { name : 'regulations', src: '/images/icons/png/regulations.png'},
                { name : 'process', src: '/images/icons/png/process.png'},
            ],
            events: [],
            activeFilter: {}
        }
    },
    computed: {
        filteredEvents() {
            if (this.activeFilter.id === -1) return this.events
            return this.events.filter(e => e.categoryId == this.activeFilter.id)
        }
    },
    created(){
        this.activeFilter = {id: -1, name: this.defaults[this.locale]}
        this.activeCategories = [this.activeFilter, ...this.categories
            .map(c => {
                return {
                    'id' : c[0],
                    'name' : c[1]
                }
            })
        ]
        this.loadData()
    },
    methods: {
        t(key) {
            if(key in this.translations) {
                return this.translations[key]
            }
            return key
        },
        loadData() {
            axios
                .get(`/api/rest/${this.locale}/get-events`, {
                    params : {
                        category_ids : this.categories.map(c => c[0])
                    }
                })
                .then((e) => {
                    this.events = e.data.events
                    this.approvedUser = e.data.approvedUser
                })
        },
        getActiveIcons(event)
        {
            return this.icons?.filter( (e) => event.categories?.includes(e.name) )
        }
    }
}
</script>
