import './design/template'
import './design/typed'
import './design/matchheight'
import './design/slideup'
import './design/animation'
import './design/accordion'

import Vue from 'vue'
import VueMatchHeights from 'vue-match-heights'

import ArticleOverview from '../components/ArticleOverview'
import NetworkPartner from '../components/NetworkPartner'
import NetworkPartnerNew from '../components/NetworkPartnerNew'
import EventOverview from '../components/EventOverview'
import RegisterButton from "../components/RegisterButton";

Vue.component('article-overview', ArticleOverview)
Vue.component('network-partner', NetworkPartner)
Vue.component('network-partner-new', NetworkPartnerNew)
Vue.component('event-overview', EventOverview)
Vue.component('register-button', RegisterButton)

Vue.use(VueMatchHeights, {})

Vue.config.ignoredElements = [/^ion-/]

if (document.getElementById("article-overview") !== null) {
    const instance = new Vue({
        el: '#article-overview',
    });
}
if (document.getElementById("network-partner") !== null) {
    const instance = new Vue({
        el: '#network-partner',
    });
}
if (document.getElementById("event-overview") !== null) {
    const instance = new Vue({
        el: '#event-overview',
    });
}
if (document.getElementById("register-button") !== null) {
    const instance = new Vue({
        el: '#register-button',
    });
}