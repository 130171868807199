if(document.getElementById('ctn')){
    (function($){
        $(document).ready(function() {
            var mode = 0;
            var delay = 20;
            var ct = 0;
            var text = [
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec aliquet lacinia urna, sit amet mattis mauris. Etiam tristique, risus pretium pellentesque elementum, urna velit feugiat dolor, auctor tempor nisl lorem vel risus. Cras commodo sodales egestas. Vestibulum hendrerit arcu id ipsum lobortis aliquam. Nunc purus odio, lacinia vitae convallis feugiat, euismod vitae lorem. Phasellus tristique metus eu ex hendrerit porta. Nam efficitur tortor vestibulum blandit ornare. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Donec posuere convallis ante, eu lacinia massa. Nulla facilisi. Aliquam erat volutpat. Praesent vel urna sed purus aliquet mollis eu ac ex.",
                "Proin malesuada velit lectus, at vestibulum enim luctus sit amet. Phasellus sagittis molestie erat in rhoncus. Vivamus vulputate dui quis nibh tempus, quis scelerisque justo ullamcorper. Nulla nisi tortor, auctor non faucibus non, venenatis ut velit. Quisque et lorem tortor. Fusce justo justo, pulvinar non lectus ut, pharetra volutpat velit. Nullam vitae sollicitudin arcu. In hac habitasse platea dictumst. Phasellus interdum molestie odio. Phasellus pellentesque purus a diam commodo semper.",
                "Fusce in dui nec nisi varius egestas et id massa. Etiam volutpat condimentum maximus. In elementum porta tellus, vitae laoreet ligula. Ut nibh velit, convallis tempus vulputate quis, pharetra at enim. Phasellus tincidunt nisl at commodo aliquam. Donec libero nulla, fringilla vitae aliquet sit amet, posuere non orci. Cras et blandit lectus. Aenean ut scelerisque massa. Etiam sollicitudin orci sit amet arcu varius tempor. Sed vel elit eget ipsum sollicitudin sollicitudin. Sed sodales, est in ultricies convallis, neque mi sagittis dui, at condimentum magna lorem eu mi. Ut blandit ullamcorper ipsum. Quisque finibus porttitor blandit. Mauris porta eros a consequat sodales. Nulla interdum bibendum urna in laoreet.",
                "Donec semper imperdiet odio vitae sagittis. Mauris et egestas elit. Ut efficitur venenatis massa, vestibulum pellentesque dui. Nullam consectetur est purus, eu luctus justo accumsan quis. Duis congue massa quis quam congue, porttitor dignissim magna suscipit. Aliquam erat volutpat. Duis mollis risus id lacus fermentum, eu finibus purus tincidunt."
            ];

            var textInner = [
                "Oonsectetur adipiscing elit. Donec aliquet lacinia urna, sit amet mattis mauris. Etiam tristique, risus pretium pellentesque elementum, urna velit feugiat dolor, auctor tempor nisl lorem vel risus. Cras commodo sodales egestas. Vestibulum hendrerit arcu id ipsum lobortis aliquam. Nunc purus odio, lacinia vitae convallis feugiat, euismod vitae lorem. Phasellus tristique metus eu ex hendrerit porta. Nam efficitur tortor vestibulum blandit ornare. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Donec posuere convallis ante, eu lacinia massa. Nulla facilisi. Aliquam erat volutpat. Praesent vel urna sed purus aliquet mollis eu ac ex.",
                "At vestibulum enim luctus sit amet. Phasellus sagittis molestie erat in rhoncus. Vivamus vulputate dui quis nibh tempus, quis scelerisque justo ullamcorper. Nulla nisi tortor, auctor non faucibus non, venenatis ut velit. Quisque et lorem tortor. Fusce justo justo, pulvinar non lectus ut, pharetra volutpat velit. Nullam vitae sollicitudin arcu. In hac habitasse platea dictumst. Phasellus interdum molestie odio. Phasellus pellentesque purus a diam commodo semper.",
                "Nisi varius egestas et id massa. Etiam volutpat condimentum maximus. In elementum porta tellus, vitae laoreet ligula. Ut nibh velit, convallis tempus vulputate quis, pharetra at enim. Phasellus tincidunt nisl at commodo aliquam. Donec libero nulla, fringilla vitae aliquet sit amet, posuere non orci. Cras et blandit lectus. Aenean ut scelerisque massa. Etiam sollicitudin orci sit amet arcu varius tempor. Sed vel elit eget ipsum sollicitudin sollicitudin. Sed sodales, est in ultricies convallis, neque mi sagittis dui, at condimentum magna lorem eu mi. Ut blandit ullamcorper ipsum. Quisque finibus porttitor blandit. Mauris porta eros a consequat sodales. Nulla interdum bibendum urna in laoreet.",
                "Imperdiet odio vitae sagittis. Mauris et egestas elit. Ut efficitur venenatis massa, vestibulum pellentesque dui. Nullam consectetur est purus, eu luctus justo accumsan quis. Duis congue massa quis quam congue, porttitor dignissim magna suscipit. Aliquam erat volutpat. Duis mollis risus id lacus fermentum, eu finibus purus tincidunt."
            ];

            var currentAngle = 0;

            var anim = {
                angle: 0
            }

            //functions
            function two(n) {
                if (n>9) return n+""; else return "0"+n;
            }
            function random(v) {
                return Math.floor(Math.random()*v)+1;
            }

            function resize() {
                width = $(window).width();
                height = $(window).height();
            }

            function setPosIco(id,r,angle) {
                TweenMax.set(id,{x:500-80+r*Math.cos(angle),y:500-80+r*Math.sin(angle)});
            }

            function anglec(angle) {
                return 180*angle/Math.PI;
            }

            function updateAngle() {
                setPosIco("#ico0",410,Math.PI * 3/2+anim.angle);
                setPosIco("#ico1",410,0+anim.angle);
                setPosIco("#ico2",410,Math.PI/2+anim.angle);
                setPosIco("#ico3",410,Math.PI+anim.angle);
                setPosIco("#ico3",410,Math.PI+anim.angle);

                TweenMax.set("#tArrows",{rotation: anglec(anim.angle)});
            }

            function checktime(){
                if (ct<=0.01 && mode==0) {
                    currentAngle+=Math.PI/2;
                    TweenMax.to(anim,1.2,{ease:Power1.easeInOut,angle:currentAngle,delay:.5,onUpdate: updateAngle});
                    ct = delay;
                } else {
                    if (mode==0) {
                        if (ct>0) ct-=0.1; else {
                            ct = delay;
                        }
                    }
                }
            }

            function init() {
                // TweenMax.set("#ctn",{display:"block"});
                TweenMax.to("#ctn",.8,{opacity:1,ease:Power1.easeInOut});
                var i;
                var s;
                var r = 80;
                var mx = 0;
                var my = 0;


                TweenMax.set("#icoi0",{transformOrigin:"0% 100%",x:500+r+mx,y:500-r-200+my,opacity:0});
                TweenMax.set("#icoi1",{transformOrigin:"0% 0%",x:500+r+mx,y:500+r+my,opacity:0});
                TweenMax.set("#icoi2",{transformOrigin:"100% 100%",x:500-r-200+mx,y:500-r-200+my,opacity:0});
                TweenMax.set("#icoi3",{transformOrigin:"100% 0%",x:500-r-200+mx,y:500+r+my,opacity:0});

                for (i=0;i<4;i++)
                    TweenMax.fromTo("#icoi"+i,1.2,{scale:.8},{scale:1,opacity:1,delay:i*.1,ease:Back.easeInOut});


                setPosIco("#ico0",410,Math.PI * 3/2);
                setPosIco("#ico1",410,0);
                setPosIco("#ico2",410,Math.PI/2);
                setPosIco("#ico3",410,Math.PI);


                TweenMax.ticker.addEventListener("tick", checktime);
                TweenMax.ticker.fps(50);

                const clickables = {
                    'ico0': 'innovation',
                    'ico1': 'process',
                    'ico2' : 'regulations',
                    'ico3' : 'technologies',

                    'icoi0' : 'ict',
                    'icoi1' : 'pharma',
                    'icoi2' : 'medical',
                    'icoi3' : 'products',
                }

                const custEvent = new CustomEvent('clusterClickEvent');

                for(let id in clickables) {
                    document.getElementById(id).addEventListener('click', e => {
                        let selector = ".slidecard__wrapper." + clickables[id]
                        if (document.querySelector(selector)) {
                            window.scrollTo({
                                top: Number(document.querySelector(selector).offsetTop) - 30,
                                behavior: "smooth"
                            })

                            document.querySelector(selector + ' > .slidecard').dispatchEvent(custEvent)
                        }
                    })
                }

                resize();
            }


            $(window).resize(resize);
            //jQuery events

            init();
        });
    })(jQuery);
}