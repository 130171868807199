<template>
    <div>
        <!-- FILTER -->
        <div class="mt-4 mt-md-8">
            <ul class="filters">
                <li
                    v-for="category in activeCategories" @click.prevent="activeFilter = category"
                    style="cursor: pointer"
                    v-bind:class="{'active-filter' : activeFilter.id == category.id}"
                >{{ category.name }}
                </li>
            </ul>
        </div>
        <!-- FILTER END -->

        <!-- PARTNERS -->
        <div class="row mt-4 mt-md-6">
            <div class="col-md-4 mb-5" v-for="partner in filteredPartners" v-match-heights="{ el : ['.news-card']}">
                <div class="news-card card-shadow">
                    <div class="news-card__header p-2">
                        <img :src="partner.logo" :alt="partner.name"/>
                    </div>
                    <div class="news-card__footer">
                        <a :href="partner.link_href" target="_blank"
                           class="button button--reverse">{{ 'app.teaser.partner.go_to_website'|trans }}</a>
                    </div>
                </div>
            </div>
        </div>
        <!-- PARTNERS END -->
    </div>
</template>

<script>
import axios from 'axios';

export default {
    name: "NetworkPartner",
    props: {
        locale: {
            type: String,
            default: 'de'
        },
        categories: {
            type: Array,
            default: []
        }
    },
    data() {
        return {
            activeCategories: [],
            partners: [],
            defaults: {
                'de': 'Alle',
                'en': 'All'
            },
            activeFilter: {}
        }
    },
    computed: {
        filteredPartners() {
            if (this.activeFilter.id === -1) return this.partners
            return this.partners
                .filter((e) => e.categoryId === this.activeFilter.id)
        }
    },
    created() {
        this.activeFilter = {id: -1, name: this.defaults[this.locale]}
        this.activeCategories = [this.activeFilter, ...this.categories
            .map(c => {
                return {
                    'id' : c[0],
                    'name' : c[1]
                }
            })
        ]
        this.loadData()
    },
    methods: {
        loadData() {
            axios
                .get(`/api/rest/${this.locale}/get-networkpartner`, {
                    params : {
                        category_ids : this.categories.map(c => c[0])
                    }
                })
                .then((e) => {
                    this.partners = e.data.partners //.sort((p1, p2) => p1.name > p2.name ? -1 : 1)
                    // this.categories = [this.activeFilter, ...e.data.categories]
                })
        }
    }
}
</script>
