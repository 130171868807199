(function () {
    let accordionFunction = () => {
        const acc = document.querySelectorAll(".accordion-btn");
        let i;
        for (i = 0; i < acc.length; i++) {
            acc[i].addEventListener("click", function () {
                this.classList.toggle("active");
                var panel = this.nextElementSibling;
                if (panel.style.maxHeight) {
                    panel.style.maxHeight = null;
                } else {
                    panel.style.maxHeight = panel.scrollHeight + "px";
                    if (this.closest(".panel")) {
                        this.closest(".panel").style.maxHeight = (this.closest(".panel").scrollHeight + panel.scrollHeight) + "px";
                    }
                }
            });
        }
    };

    const oldOnloads = window.onload
    window.onload = () => {
        if ( oldOnloads ) oldOnloads()
        accordionFunction()
    }
})()
