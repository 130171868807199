import Typed from 'typed.js'

/* do only if the hero header is present */
if(document.getElementById('headerTitle')) {
    let texts = [...document.querySelectorAll('.header__body>.typing_texts')].map((i) => i.value)
    setTimeout(() => {
        let t = new Typed('#headerTitle', {
            strings: texts,
            typeSpeed: 200,
            backSpeed: 0,
            fadeOut: true,
            loop: true,
            cursorChar: "."
        })
    }, 2000)
}
