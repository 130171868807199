<template>
    <div>
        <!-- FILTER -->
        <div class="mt-4 mt-md-8">
            <ul class="filters">
                <li v-for="category in activeCategories" @click.prevent="activeFilter = category"  v-bind:class="{ 'active-filter' : activeFilter.id == category.id }">{{ category.name }} </li>
            </ul>
        </div>
        <!-- FILTER END -->

        <!-- PARTNERS -->
        <div class="row mt-4 mt-md-6">
            <div class="col-md-4 mb-5" v-for="article in filteredArticles" v-match-heights="{ el : ['.news-card__body']}">
                <div class="news-card card-shadow">
                    <div class="news-card__header p-2">
                        <div class="news-card__tag">{{ article.tags[0].tag }}</div>
                        <img :src="article.image" :alt="article.title" />
                    </div>
                    <div class="news-card__body">
                        <h3 class="heading-tertiary news-card__heading">{{ article.title }}</h3>
                        <p class="news-card__desc">{{ article.teaser }}</p>
                    </div>
                    <div class="news-card__footer" v-if="article.url">
                        <a :href="article.url" class="button button--reverse">
                            {{ t('article.teaser.link') }}
                            <i v-if="! article.public" class="ml-1 fas" v-bind:class="{ 'fa-lock-open' : approvedUser, 'fa-lock' : !approvedUser}"></i>
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <!-- PARTNERS END -->
    </div>
</template>

<script>
import axios from 'axios';

export default {
    name: "ArticleOverview",
    props: {
        locale: {
            type: String,
            default: 'de'
        },
        translations: {
            type: Object
        },
        categories : {
            type: Array,
            default: []
        }

    },
    data() {
        return {
            activeCategories: [],
            articles: [],
            defaults: {
                'de': 'Alle',
                'en': 'All'
            },
            approvedUser: false,
            activeFilter: {}
        }
    },
    computed: {
        filteredArticles() {
            if(this.activeFilter.id === -1) return this.articles

            return this.articles
                .filter( e => {
                    return e.tags.filter(t => t.id == this.activeFilter.id).length
                })
        }
    },
    created() {
        this.activeFilter = {id: -1, name: this.defaults[this.locale]}
        this.activeCategories = [this.activeFilter, ...this.categories
            .map(c => {
                return {
                    'id' : c[0],
                    'name' : c[1]
                }
            })
        ]
        this.loadData();
    },
    methods: {
        t(key) {
            if(key in this.translations) {
                return this.translations[key]
            }
            return key
        },
        loadData() {
            axios
                .get(`/api/rest/${this.locale}/get-articles`, {
                    params : {
                        category_ids : this.categories.map(c => c[0])
                    }
                })
                .then((e) => {
                    this.articles = e.data.articles
                    this.approvedUser = e.data.approvedUser
                    this.tags = [this.activeFilter, ...e.data.tags]
                })
        }
    }

}
</script>
