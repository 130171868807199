<template>
    <div>
        <div v-if="!loading" class="comp-content">
            <div v-if="registration === false" class="button-container">
                <button @click="register" class="button button--light">{{ t('event.register')}}</button>
            </div>
            <div v-if="registration && registration.registeredAt !== false && registration.canceledAt === false">
                {{ t('event.registered_at') }} {{ registration.registeredAt }}<br>
                <button @click="modalOpen = true" class="button button--light">Absagen</button>
            </div>
            <div v-if="registration && registration.canceledAt !== false">
                {{ t('event.canceled_at') }} {{ registration.canceledAt}}<br>
                <span class="cancel-reason"><strong>{{ t('event.reason')}}</strong> {{ registration.cancelMsg }}</span>
            </div>
        </div>
        <div v-else>loading...</div>


        <!-- The Modal -->
        <div id="myModal" class="modal" :class="{'modal-open' : modalOpen, 'modal-closed' : !modalOpen}">
            <!-- Modal content -->
            <div class="modal-content">
                <span class="close" @click="modalOpen = false">&times;</span>
                <h3>{{ t('event.cancel.title')}}</h3>
                <p class="my-1">
                    {{ t('event.cancel.text') }}
                </p>
                <textarea class="p-1" v-model="cancelMsg"></textarea>
                <div v-if="minChars > cancelMsg.length" class="hint">{{ t('event.please_enter_x_signs').replace('%x', (minChars - cancelMsg.length)) }}</div>
                <button @click="cancel" :disabled="cancelMsg.length < minChars">{{ t('event.cancel.approve') }}</button>
            </div>
        </div>

    </div>
</template>

<script>
import axios from 'axios'

export default {
    name: "RegisterButton",
    props : {
        eventId : {
            type: Number
        },
        translations: {
            type: Object
        },
        minChars : {
            type: Number,
            default: 20
        }
    },
    data() {
        return {
            loading: false,
            registration : null,
            modalOpen: false,
            cancelMsg: ''
        }
    },
    created() {
        this.hitApi('status');
    },
    methods: {
        t(key) {
            if(key in this.translations) {
                return this.translations[key]
            }
            return key
        },
        hitApi(action, data = {}) {
            this.loading = true;
            axios.post(`/api/rest/event/${action}`, {
                eventId : this.eventId, ...data
            })
                .then((r) => {
                    this.loading = false;
                    this.registration = r.data.registration
                })
                .catch((e) => console.log(e))
        },
        register() {
            this.hitApi('register')
        },
        cancel() {
            this.modalOpen = false;
            this.hitApi('cancel', {
                msg: this.cancelMsg
            })
        },
        cancelModal(){
            this.modalOpen = true;
        }
    }
}
</script>

<style scoped lang="scss">

textarea {
    width: 100%;
    height: 5em;
}

h3{
    font-size: 1.4em;
}

.comp-content{
    width: 100%;
    .button-container{
        justify-content: center;
    }
}

.hint {
    font-size: 0.9em;
    font-style: italic;
    line-height: 1;
    margin-bottom: 1em;
}

.cancel-reason {
    font-style: italic;
}

.modal {
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}

.modal-closed {
    display: none;
}

.modal-open {
    display: block;
}

/* Modal Content/Box */
.modal-content {
    color: #000000;
    background-color: #fefefe;
    margin: 15% auto; /* 15% from the top and centered */
    padding: 20px;
    border: 1px solid #888;
    max-width: 500px;
}

/* The Close Button */
.close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
}

.close:hover,
.close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
}
</style>
