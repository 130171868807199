// NAVBAR
(function () {
    if (document.querySelector("#burger")) {
        let navbarBurger = document.querySelector("#burger");

        navbarBurger.addEventListener("click", function () {
            navbarBurger.closest(".navbar").classList.toggle("navbar--active");
            document.documentElement.classList.toggle("navbar--fixed");
        });
    }
})();

// HEADER
(function () {
    if (document.querySelector(".header")) {
        let header = document.querySelector(".header");

        // Background move
        header.addEventListener("mousemove", function (e) {
            var amountMovedX = (e.pageX * 1) / 20;
            var amountMovedY = (e.pageY * 1) / 20;
            this.style.backgroundPosition = amountMovedX + "% " + amountMovedY + "%";
        });
    }
})();

// SECTION CLUSTER MODAL
if (document.querySelector("#cluster-play")) {

    setTimeout(() => {
        // YouTube API
        var player;

        //  This code loads the IFrame Player API code asynchronously.
        var tag = document.createElement("script");

        tag.src = "https://www.youtube.com/iframe_api";
        var firstScriptTag = document.getElementsByTagName("script")[0];
        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

        //  This function creates an <iframe> (and YouTube player)
        //    after the API code downloads.
        function onYouTubeIframeAPIReady() {
            console.log('player is ready')
            player = new YT.Player("cluster-player", {
                videoId: "M7lc1UVf-VE",
            });
        }

        let playButton = document.querySelector("#cluster-play");
        let modal = document.querySelector(".section-cluster__modal-wrapper");
        let modalClose = document.querySelector(".section-cluster__modal-close");

        playButton.addEventListener("click", function () {
            this.closest(".section-cluster").classList.add("section-cluster--active");
            player.playVideo();
        });

        modal.addEventListener("click", function (e) {
            if (this == e.target) {
                this.closest(".section-cluster").classList.remove("section-cluster--active");
                player.stopVideo();
            }
        });

        modalClose.addEventListener("click", function (e) {
            this.closest(".section-cluster").classList.remove("section-cluster--active");
            player.stopVideo();
        });
    }, 1000)
}

// NEWS SWIPER
(function () {
    if (document.querySelector(".news-swiper")) {
        var mySwiper = new Swiper(".news-swiper", {
            loop: true,
            slidesPerView: 1,
            spaceBetween: 30,
            autoplay: {
                delay: 5000,
            },
            // If we need pagination
            pagination: {
                el: ".swiper-pagination",
            },

            // Navigation arrows
            navigation: {
                nextEl: ".news-button-next",
                prevEl: ".news-button-prev",
            },

            breakpoints: {
                // when window width is >= 768px
                768: {
                    slidesPerView: 3,
                },
            },
        });
    }
})();

// PARTNER SWIPER
(function () {
    if (document.querySelector(".partners-swiper")) {
        var partnerSwiper = new Swiper(".partners-swiper", {
            loop: true,
            slidesPerView: 1,
            spaceBetween: 0,
            grabCursor: true,
            autoplay: {
                delay: 2000,
            },
            breakpoints: {
                // when window width is >= 768px
                576: {
                    slidesPerView: 2,
                },
                768: {
                    slidesPerView: 4,
                },
            },
            // pagination: {
            //     el: ".swiper-pagination",
            //     clickable: true,
            // },
        });
    }
})();


// TESTIMONIAL SWIPER
(function () {
    if (document.querySelector(".testimonial-swiper")) {
        var mySwiper = new Swiper(".testimonial-swiper", {
            loop: true,
            slidesPerView: 1,
            spaceBetween: 30,

            // If we need pagination
            pagination: {
                el: ".swiper-pagination",
            },

            // Navigation arrows
            navigation: {
                nextEl: ".testimonial-button-next",
                prevEl: ".testimonial-button-prev",
            },

            breakpoints: {
                // when window width is >= 768px
                768: {
                    slidesPerView: 2,
                },
            },
        });
    }
})();


// // NAVBAR DROP
// (function () {
//     let navbarDropdown = document.querySelectorAll(".dropdown__trigger");
//
//     navbarDropdown.forEach(function (el) {
//         if (window.innerWidth >= 992) {
//             if (el.parentElement.classList.contains("navbar__menu-item")) {
//                 el.insertAdjacentHTML("beforeend", '<ion-icon name="chevron-down-outline"></ion-icon>');
//             }
//         }
//
//         el.addEventListener("click", function (e) {
//             let currentElement = this.closest(".dropdown");
//             currentElement.classList.toggle("dropdown--active");
//
//             if (currentElement.classList.contains("dropdown--active")) {
//                 currentElement.querySelector(".dropdown__menu").style.maxHeight =
//                     currentElement.querySelector(".dropdown__menu").scrollHeight + "px";
//             } else {
//                 currentElement.querySelector(".dropdown__menu").style.maxHeight = null;
//             }
//         });
//     });
// })();
